import {isArray, IShapeValidators, isNumber, isString, or, refine} from './utils/basicValidators';
import {
  IFilter,
  IFilterDescription,
  isAutoCompleteFilter,
  isEnumFilter,
  isEnumLazyFilter,
  isRangeFilter,
  isSearchFilter,
  isTextFilter,
  isTimeFilter
} from './filterTypes';

export type IFilterValidators<K extends string, FD extends IFilterDescription<K>> = IShapeValidators<IFilter<K, FD>>;

export const createFilterValidators = <K extends string, FD extends IFilterDescription<K>>(
  filterDescription: FD
): IFilterValidators<K, FD> => {
  type FV = IFilterValidators<K, FD>;
  const fdEntries = Object.entries(filterDescription) as Array<[K, FD[K]]>;
  const validators = fdEntries.reduce(<_K extends K>(acc: Array<[K, FV[K]]>, [key, value]: [_K, FD[_K]]) => {
    let validator;
    if (isTextFilter(value) || isAutoCompleteFilter(value)) {
      validator = isString;
    } else if (isRangeFilter(value)) {
      const min = typeof value.min === 'undefined' ? -Infinity : value.min;
      const max = typeof value.max === 'undefined' ? Infinity : value.max;
      validator = refine(isArray(refine(isNumber, (v) => min <= v && v <= max)), (v) => v.length === 2);
    } else if (isTimeFilter(value)) {
      const min = typeof value.min === 'undefined' ? 0 : value.min;
      const max = typeof value.max === 'undefined' ? Number.MAX_SAFE_INTEGER : value.max;
      validator = refine(
        isArray(
          refine(isNumber, (v) => (min === 'now' ? Date.now() : min) <= v && v <= (max === 'now' ? Date.now() : max))
        ),
        (v) => v.length === 2
      );
    } else if (isEnumFilter(value)) {
      const values = value.options.map(({value}) => value);
      validator = isArray(<T extends string | number>(v: unknown): v is T => values.includes(v as T));
    } else if (isSearchFilter(value) || isEnumLazyFilter(value)) {
      validator = isArray(or(isString, isNumber));
    }
    if (typeof validator === 'function') {
      acc.push([key, validator as FV[_K]]);
    } else {
      console.error('Invalid filter description', key, value);
    }
    return acc;
  }, [] as Array<[K, FV[K]]>);
  return (Object.fromEntries(validators) as unknown) as FV;
};
