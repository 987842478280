/** @jsx createElement */
import {
  createContext,
  createElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  createPreferencesDefaults,
  createPreferencesValidator,
  IPreferences,
  readPreferences,
  savePreferences
} from '../preferences';
import { isEqual } from 'lodash-es';
import { IColumn, ITableProps } from '../ITableProps';
import { entries, fromEntries, mapKeys } from '../utils/entries';
import { createFilterValidators } from '../filterValidators';
import { IShapeValidators } from '../utils/basicValidators';
import { history, SearchParams } from '@netvision/lib-history';
import { createPageValidators, getPageDefaults, IPage } from '../page';
import { ISetter, IUpdater } from '../utils/types';
import { IFilter, IFilterDescription } from '../filterTypes';
import { getFilterDefaults } from '../filterDefaults';
import { useLocationProp } from './useLocationProp';
import { ISerializable } from '@netvision/lib-history/dist/searchParams';

const PageCtx = createContext<IPage<any>>(null!);

export const usePage = <K extends string>(): IPage<K> => {
  return useContext(PageCtx);
};

const SetPageCtx = createContext<IUpdater<IPage<any>>>(null!);

export const useSetPage = <K extends string>(): IUpdater<IPage<K>> => {
  return useContext(SetPageCtx);
};

const FilterCtx = createContext<IFilter<any, any>>(null!);

export const useFilter = <K extends string, FD extends IFilterDescription<K>>(): IFilter<K, FD> => {
  return useContext(FilterCtx);
};

const SetFilterCtx = createContext<IUpdater<IFilter<any, any>>>(null!);

export const useSetFilter = <K extends string, FD extends IFilterDescription<K>>(): IUpdater<IFilter<K, FD>> => {
  return useContext(SetFilterCtx);
};

const PreferencesDefaultsCtx = createContext<IPreferences<any>>(null!);

export const usePreferencesDefaults = <K extends string>(): IPreferences<K> => {
  return useContext(PreferencesDefaultsCtx);
};

const PreferencesCtx = createContext<IPreferences<any>>(null!);

export const usePreferences = <K extends string>(): IPreferences<K> => {
  return useContext(PreferencesCtx);
};

const SetPreferencesCtx = createContext<IUpdater<IPreferences<any>>>(null!);

export const useSetPreferences = <K extends string>(): IUpdater<IPreferences<K>> => {
  return useContext(SetPreferencesCtx);
};

const ColumnsCtx = createContext<IColumn<any, any>[]>(null!);

export const useColumns = <T extends {}, K extends string>(): IColumn<T, K>[] => {
  return useContext(ColumnsCtx);
};

const SelectedColumnsCtx = createContext<IColumn<any, any>[]>(null!);

export const useSelectedColumns = <T extends {}, K extends string>(): IColumn<T, K>[] => {
  return useContext(SelectedColumnsCtx);
};

const TablePropertiesCtx = createContext<ITableProps<any, any, any, any>>(null!);

export const useTableProperties = <
  T extends {},
  K extends string,
  FK extends K,
  FD extends IFilterDescription<FK>
>(): ITableProps<T, K, FK, FD> => {
  return useContext(TablePropertiesCtx);
};

const FilterDescriptionCtx = createContext<IFilterDescription<any>>(null!);

export const useFilterDescription = <K extends string>(): IFilterDescription<K> => {
  return useContext(FilterDescriptionCtx);
};

const DataSourceCtx = createContext<ITableProps<any, any, any, any>['dataSource']>(null!);

export const useDataSource = <
  T extends {},
  K extends string,
  FK extends K,
  FD extends IFilterDescription<FK>
>(): ITableProps<T, K, FK, FD>['dataSource'] => {
  return useContext(DataSourceCtx);
};

const GroupingFieldsCtx = createContext<string[]>(null!);
export const useGroupingFields = () => useContext(GroupingFieldsCtx);

const FilterDefaultsCtx = createContext<IFilter<any, any>>(null!);

export const useFilterDefaults = <K extends string, FD extends IFilterDescription<K>>(): IFilter<K, FD> =>
  useContext(FilterDefaultsCtx);

const PageDefaultsCtx = createContext<IPage<any>>(null!);

export const usePageDefaults = <K extends string>(): IPage<K> => useContext(PageDefaultsCtx);

export function TableStateProvider<T extends {}, K extends string, FK extends K, FD extends IFilterDescription<FK>>(
  props: ITableProps<T, K, FK, FD> & {children?: ReactNode}
) {
  const search = useLocationProp('search');
  const {
    columns,
    defaultPage,
    filterDescription,
    defaultGroupingFields,
    requireSortField = true,
    preferencesLocalStorageKey: LSKey,
    children
  } = props;
  const pageDefaults = useMemo(() => getPageDefaults(defaultPage), [defaultPage]);
  const filterDefaults = useMemo(() => getFilterDefaults(filterDescription), [filterDescription]);
  const sortSettings = useMemo(() => ({requireSortField}), [requireSortField]);
  const pageValidators = useMemo(() => createPageValidators(columns), [columns]);
  const filterValidators = useMemo(() => createFilterValidators<FK, FD>(filterDescription), [filterDescription]);
  const pageRef = useRef<typeof pageDefaults>(pageDefaults);
  const filterRef = useRef<typeof filterDefaults>(filterDefaults);
  const [page, filter] = useMemo(() => {
    const page = toShape(
      mapKeys(SearchParams.parse(search), (key) => key.replace('p__', '')),
      // @ts-ignore
      pageValidators,
      pageDefaults
    );
    const filter = toShape(
      mapKeys(SearchParams.parse(search), (key) => key.replace('f__', '')),
      filterValidators,
      filterDefaults
    );
    // @ts-ignore
    pageRef.current = page;
    filterRef.current = filter;
    // @ts-ignore
    return [page, filter] as [typeof pageDefaults, typeof filterDefaults];
  }, [search, pageDefaults, pageValidators, filterDefaults, filterValidators]);
  const updateURL = useCallback(
    (page: typeof pageDefaults, filter: typeof filterDefaults) => {
      if (sortSettings.requireSortField && page.sortField === null) {
        page.sortField = pageDefaults.sortField;
        page.sortOrder = pageDefaults.sortOrder;
      }
      const pageSearch = SearchParams.stringify(
        // @ts-ignore
        mapKeys(removeDefaults(page, pageValidators, pageDefaults), (key) => `p__${key}`)
      );
      const filterSearch = SearchParams.stringify(
        mapKeys(removeDefaults(filter, filterValidators, filterDefaults), (key) => `f__${key}`)
      );
      history.push({
        search: [pageSearch, filterSearch].filter((v) => v.length > 0).join('&'),
        hash: window.location.hash
      });
    },
    [pageDefaults, filterDefaults, pageValidators, filterValidators, sortSettings]
  );
  const setPage = useCallback(
    (setter: ISetter<typeof pageDefaults>) => {
      const res = setter(pageRef.current);
      const filter = filterRef.current;
      updateURL(res, filter);
    },
    [updateURL]
  );
  const setFilter = useCallback(
    (setter: ISetter<typeof filterDefaults>) => {
      const res = setter(filterRef.current);
      const page = pageRef.current;
      updateURL(page, res);
    },
    [updateURL]
  );
  /**
   * Preferences
   */
  const preferencesDefaults = useMemo(() => {
    return createPreferencesDefaults(columns, defaultGroupingFields);
  }, [defaultGroupingFields, columns]);
  const preferencesValidators = useMemo(createPreferencesValidator, []);
  const [preferences, setPreferences] = useState(preferencesDefaults);
  useLayoutEffect(() => {
    if (LSKey) {
      setPreferences(
        toShape(readPreferences(LSKey), preferencesValidators, preferencesDefaults) as typeof preferencesDefaults
      );
    }
    // eslint-disable-next-line
  }, [LSKey]);
  useLayoutEffect(() => {
    if (!LSKey) {
      setPreferences(preferencesDefaults);
    }
    // eslint-disable-next-line
  }, [LSKey, preferencesDefaults]);
  useLayoutEffect(() => {
    if (LSKey) {
      savePreferences(LSKey, preferences);
    }
    // eslint-disable-next-line
  }, [preferences]);
  /** If local storage does not have some required or filtered columns, they will be added here */
  useEffect(() => {
    const current = addMissingColumns(
      getNonDefaultFilterKeys(filter, filterDefaults),
      columns,
      preferences.selectedColumnFields
    );
    if (!isEqual(current, preferences.selectedColumnFields)) {
      setPreferences((prev) => {
        return {
          ...prev,
          selectedColumnFields: current
        };
      });
    }
    // eslint-disable-next-line
  }, []);
  const selectedColumns = useMemo(
    () => getSelectedColumns(columns, preferences.selectedColumnFields),
    [columns, preferences.selectedColumnFields]
  );
  return (
    <TablePropertiesCtx.Provider value={props}>
      <ColumnsCtx.Provider value={props.columns}>
        <DataSourceCtx.Provider value={props.dataSource}>
          <FilterDescriptionCtx.Provider value={filterDescription}>
            <FilterDefaultsCtx.Provider value={filterDefaults}>
              <FilterCtx.Provider value={filter}>
                <SetFilterCtx.Provider value={setFilter}>
                  <PageDefaultsCtx.Provider value={pageDefaults}>
                    <PageCtx.Provider value={page}>
                      <SetPageCtx.Provider value={setPage}>
                        <PreferencesDefaultsCtx.Provider value={preferencesDefaults}>
                          <PreferencesCtx.Provider value={preferences}>
                            <SetPreferencesCtx.Provider value={setPreferences}>
                              <SelectedColumnsCtx.Provider value={selectedColumns}>
                                {children}
                              </SelectedColumnsCtx.Provider>
                            </SetPreferencesCtx.Provider>
                          </PreferencesCtx.Provider>
                        </PreferencesDefaultsCtx.Provider>
                      </SetPageCtx.Provider>
                    </PageCtx.Provider>
                  </PageDefaultsCtx.Provider>
                </SetFilterCtx.Provider>
              </FilterCtx.Provider>
            </FilterDefaultsCtx.Provider>
          </FilterDescriptionCtx.Provider>
        </DataSourceCtx.Provider>
      </ColumnsCtx.Provider>
    </TablePropertiesCtx.Provider>
  );
}

const getNonDefaultFilterKeys = <F extends IFilter<any, any>>(filter: F, defaults: F): Array<keyof F> => {
  return entries(filter)
    .filter(([key, value]) => !isEqual(value, defaults[key]))
    .map(([key]) => key);
};

const addMissingColumns = <K extends string, F extends IFilter<any, any>>(
  nonDefaultFilters: Array<keyof F>,
  columns: IColumn<any, K>[],
  selectedColumnNames: string[]
): K[] => {
  const filterKeySet = new Set(nonDefaultFilters);
  const selectedColumnsSet = new Set(getSelectedColumns(columns, selectedColumnNames).map((col) => col.field));
  return columns
    .filter(
      // include columns that are required or already selected or filtered
      ({field, requiredColumn}) => requiredColumn || selectedColumnsSet.has(field) || filterKeySet.has(field)
    )
    .map((col) => col.field);
};

const getSelectedColumns = <C extends IColumn<any, any>>(columns: C[], selectedColumnNames: string[]): C[] => {
  const set = new Set(selectedColumnNames);
  return columns.filter((col) => set.has(col.field));
};

const toShape = <T extends ISerializable, K extends keyof T>(
  parsed: {[x: string]: unknown},
  validator: IShapeValidators<T>,
  defaults: T
): T => {
  return entries(parsed).reduce((acc, [key, value]) => {
    if (key in validator && validator[key as K](value) && !isEqual(defaults[key as K], value)) {
      acc[key as K] = value;
    }
    return acc;
  }, Object.assign({}, defaults));
};

const removeDefaults = <T extends ISerializable>(obj: T, validator: IShapeValidators<T>, defaults: T): Partial<T> => {
  return fromEntries(entries(obj).filter(([key, value]) => validator[key]?.(value) && !isEqual(defaults[key], value)));
};
