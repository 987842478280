import { identity } from 'lodash-es';
import { ISearchQueryPayload } from '@netvision/lib-api-repo';

export const createComposeDefaultQ = (defaultQueryParam?: ISearchQueryPayload) => {
  return !defaultQueryParam
    ? identity
    : (q: ISearchQueryPayload[] | undefined) => (
      q ? q.concat(defaultQueryParam) : [defaultQueryParam]
    )
};
