import {IValidator} from './basicValidators';

export const branch = <T>(validator: IValidator<T>) => (v: unknown) => <L, R>(
  onRight: (v: T) => R,
  onLeft?: () => L
) => {
  if (validator(v)) {
    onRight(v);
  } else {
    typeof onLeft === 'function' && onLeft();
  }
};
