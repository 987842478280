import {css} from '@emotion/react';

export const $smButton = css`
  &.p-button:not(disabled) {
    height: calc(30rem / var(--bfs));
    width: calc(40rem / var(--bfs));
    font-size: calc(16rem / var(--bfs));
  }
  &.p-button.p-button-secondary:not(disabled) {
    color: var(--text-color-secondary);
  }
`;
