import React, { createContext, FC, Fragment, ReactNode, useCallback, useContext } from 'react';
import { history } from '@netvision/lib-history';
import { useLocationProp } from './useLocationProp';

const IsSettingsCtx = createContext<boolean>(null!);

export const useIsSettings = () => {
  return useContext(IsSettingsCtx);
};

const GoToSettingsCtx = createContext<(value: boolean) => void>(null!);

export const useGoToSettings = () => {
  return useContext(GoToSettingsCtx);
};

export const SettingsRoute: FC<{left: ReactNode; right: ReactNode}> = ({left, right}) => {
  const isSettings = useIsSettings();
  return <Fragment>{isSettings ? right : left}</Fragment>;
};

export const RoutingProvider: FC = ({children}) => {
  const hash = useLocationProp('hash');
  const showSettings = hash === '#settings';
  const toSettings = useCallback((value: boolean) => {
    const hash = '#settings';
    if (value) {
      history.push({search: history.location.search, hash});
    } else {
      history.push({search: history.location.search});
    }
  }, []);
  return (
    <GoToSettingsCtx.Provider value={toSettings}>
      <IsSettingsCtx.Provider value={showSettings}>{children}</IsSettingsCtx.Provider>
    </GoToSettingsCtx.Provider>
  );
};
