export const entries = <T extends Object, K extends keyof T>(object: T): Array<Entry<T, K>> => {
  return Object.entries(object) as Array<Entry<T, K>>;
};

export const fromEntries = <T extends Object, K extends keyof T>(entries: Entry<T, K>[]): Partial<T> => {
  return (Object.fromEntries(entries) as unknown) as Partial<T>;
};

export type Entry<T extends Object, K extends keyof T> = [K, T[K]];

export const updateValues = <T extends Object>(object: T, f: <K extends keyof T>(value: T[K], key: K) => T[K]): T => {
  const updatedEntries: Entry<T, keyof T>[] = entries(object).map(([key, value]) => [key, f(value, key)]);
  return (Object.fromEntries(updatedEntries) as unknown) as T;
};

export const mapValues = <T extends Object, R extends any>(
  object: T,
  f: <K extends keyof T>(value: T[K], key: K) => R
) => {
  return updateValues(object, f as any) as {
    [P in keyof T]: R;
  };
};

export const mapKeys = <T extends object, K1 extends Exclude<keyof T, number | symbol>, K2 extends string>(
  object: T,
  f: (key: K1) => K2
): {[P in K2]: T[keyof T]} => {
  const mapped = {} as {[P in K2]: T[keyof T]};
  (Object.keys(object) as K1[]).forEach((key) => {
    mapped[f(key)] = object[key];
  });
  return mapped;
};
