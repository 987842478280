const datePicker = {
  firstDayOfWeek: 1,
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
  today: 'Сегодня',
  clear: 'Очистить',
  weekHeader: 'Неделя'
};

const calendarButtons = {
  today: 'Сегодня',
  yesterday: 'Вчера',
  week: 'Неделя',
  month: 'Месяц'
};

const exportLocale = {
  title: 'Экспорт',
  cancel: 'Отмена',
  all: 'Все страницы',
  format: 'Формат',
  onlyPagesFromTo: 'Только с {{1}} по {{2}}',
  progress: '{{1}} из {{2}}',
  exporting: 'Идет загрузка страниц...',
  error: 'Ошибка, попробуйте снова'
};

const settingsLocale = {
  columnsTitle: 'Отображать столбцы',
  groupingTitle: 'Группировка'
};

export const defaultLocale = {
  from: 'из',
  backLabel: 'Назад',
  settingsLabel: 'Настройки',
  applyFilters: 'Применить',
  cancelFilters: 'Отменить',
  resetFilters: 'Сбросить',
  resetAllFilters: 'Сбросить фильтры',
  selectedLabel: 'Выбрано',
  selectAll: 'Выбрать все',
  reset: 'По умолчанию',
  total: 'Всего',
  records: 'Записей',
  pages: 'Страниц',
  refresh: 'Обновить',
  cancel: 'Отменить',
  clear: 'Очистить',
  all: 'Все',
  searchByName: 'Поиск',
  selectDateRange: 'Выбрать промежуток',
  noRecords: 'Данные отсутствуют',
  resultsNotFound: 'Ничего не найдено. Попробуйте изменить фильтры',
  groupRows: 'Группировка',
  groupEvents: 'Группировать события',
  datePicker,
  exportLocale,
  settingsLocale,
  calendarButtons
};

export type Locale = typeof defaultLocale;
