/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, ReactFragment, useMemo} from 'react';
import {Button} from 'primereact/button';
import {useGoToSettings, useIsSettings} from '../hooks/tabRouting';
import {GroupBy} from './GroupBy';
import {useLocale} from '../hooks/locale';
import {ITab} from '../ITableProps';
import {ProgressBar} from 'primereact/progressbar';
import {useData} from '../hooks/data';
import {useLocationProp} from '../hooks/useLocationProp';
import {history} from '@netvision/lib-history';
import {ISimpleObservable, simpleObservable} from '../utils/simpleObservable';
import {useObservable} from '../hooks/useObservable';
import {$smButton} from './ui/smButtonCss';
import {useFilter, useFilterDefaults, useSetFilter, useTableProperties} from '../hooks/tableState';
import {isEqual} from 'lodash-es';

export const TableHeader: FC<{
  title: string;
  tabs?: ITab[];
  additionalControls?: ReactFragment;
  loadingObs?: ISimpleObservable<boolean>;
}> = ({title, tabs, additionalControls, loadingObs}) => {
  const isSettings = useIsSettings();
  return isSettings ? (
    <SettingsHeader title={title} />
  ) : (
    <Tabs loadingObs={loadingObs} title={title} tabs={tabs} additionalControls={additionalControls} />
  );
};

const fallbackObs = simpleObservable(false);

const Tabs: FC<{
  title: string;
  tabs?: ITab[];
  additionalControls?: ReactFragment;
  loadingObs?: ISimpleObservable<boolean>;
}> = ({title, tabs, additionalControls, loadingObs}) => {
  const goToSettings = useGoToSettings();
  const {isGroupingOptional = true} = useTableProperties();
  const locale = useLocale();
  const [loading] = useData();
  const externalLoading = useObservable(loadingObs ?? fallbackObs);
  const filterDefaults = useFilterDefaults();
  const filter = useFilter();
  const setFilter = useSetFilter();
  const filterActive = useMemo(() => !isEqual(filterDefaults, filter), [filter, filterDefaults]);
  const head = (
    <div css={s.buttons}>
      <Button
        className={'p-button-secondary p-button-outlined p-button-sm'}
        css={$smButton}
        icon={'mdi mdi-cog'}
        tooltip={locale.settingsLabel}
        onClick={() => {
          goToSettings(true);
        }}
        data-cy='setting-button'
      />
      {filterActive && (
        <Button
          className={'p-button-outlined p-button-sm'}
          css={$smButton}
          icon={'mdi mdi-filter-remove'}
          tooltip={locale.resetAllFilters}
          onClick={() => {
            setFilter(() => filterDefaults);
          }}
        />
      )}
    </div>
  );
  const tail = (
    <Fragment>
      {isGroupingOptional && <GroupBy />}
      {additionalControls}
    </Fragment>
  );
  return <Header loading={loading || externalLoading} title={title} tabs={tabs} head={head} tail={tail} />;
};

const SettingsHeader: FC<{title: string}> = ({title}) => {
  const goToSettings = useGoToSettings();
  const locale = useLocale();
  const head = (
    <div css={s.buttons}>
      <Button
        className={'p-button-secondary p-button-outlined p-button-sm'}
        css={$smButton}
        icon={'mdi mdi-arrow-left'}
        tooltip={locale.backLabel}
        onClick={() => {
          goToSettings(false);
        }}
        data-cy='filters-close-button'
      />
    </div>
  );
  return <Header title={`${locale.settingsLabel}: ${title.toLowerCase()}`} head={head} />;
};

const Header: FC<{loading?: boolean; title: string; tabs?: ITab[]; head?: JSX.Element; tail?: JSX.Element}> = ({
  loading,
  title,
  tabs,
  head,
  tail
}) => {
  const pathname = useLocationProp('pathname');
  return (
    <div css={s.container}>
      {loading && <ProgressBar mode="indeterminate" css={s.progressBar} />}
      <div css={s.head}>
        {!!tabs ? (
          tabs.map(({title, link}) => {
            const active = pathname.startsWith(link);
            return (
              <NavLink
                key={link}
                href={link}
                aria-current={active ? 'page' : undefined}
                css={[s.title, s.navLink, active]}>
                <h1>{title}</h1>
              </NavLink>
            );
          })
        ) : (
          <div css={s.title}>
            <h1 data-cy='analytic-setting-title'>{title}</h1>
          </div>
        )}
        {head}
      </div>
      <div css={s.tail}>{tail}</div>
    </div>
  );
};

// language=SCSS
const s = {
  container: css`
    & {
      position: relative;
      display: flex;
      align-items: center;

      margin-bottom: var(--spacer-xs);
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: calc(1rem / var(--bfs));
      width: 100%;
      background: var(--text-color-secondary);
    }
  `,
  navLink: css`
    & {
      text-decoration: none;
      color: var(--text-color);
      align-self: baseline;
    }
    &:not([aria-current='page']) > h1 {
      font-size: var(--font-size-h2);
      color: var(--text-color-secondary);
    }
    &:not([aria-current='page']):before {
      content: none;
    }
  `,
  title: css`
    & {
      position: relative;
      flex-shrink: 1;
    }
    & > h1 {
      padding: calc(20rem / var(--bfs)) 0;
      font-size: var(--font-size-h1);
      font-weight: 500;
      margin: 0;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: calc(-3rem / var(--bfs));
      left: 0;
      height: calc(4rem / var(--bfs));
      width: 100%;
      background: var(--primary-color);
    }
  `,
  head: css`
    & {
      flex-grow: 1;

      display: flex;
      align-items: center;
    }
    & > * {
      margin-right: var(--spacer);
    }
  `,
  tail: css`
    & {
      display: flex;
      align-items: center;
    }
    & > * {
      margin-left: var(--spacer);
    }
    & > *:first-child {
      margin-left: 0;
    }
  `,
  progressBar: css`
    & {
      position: absolute;
      width: 100%;
      height: calc(1rem / var(--bfs));
      left: 0;
      bottom: 0;
    }
  `,
  buttons: css`
    > button {
      margin-right: var(--spacer-xs);
    }
    > button:last-child {
      margin-right: 0;
    }
  `
};

const NavLink: FC<JSX.IntrinsicElements['a']> = ({href, ...rest}) => {
  return (
    <a
      href={href}
      onClick={(e) => {
        if (href?.startsWith('/')) {
          e.preventDefault();
          history.push(href);
        }
      }}
      {...rest}
    />
  );
};
