import React, { FC, useContext, useMemo } from 'react';
import { defaultLocale, Locale } from '../locale';
import { defaultsDeep } from 'lodash-es';

const LocaleContext = React.createContext(defaultLocale);

export const useLocale = () => useContext(LocaleContext);

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type LocaleOverrides = RecursivePartial<Locale>;

export const LocaleProvider: FC<{overrides?: LocaleOverrides}> = ({overrides, children}) => {
  const value = useMemo(() => {
    return typeof overrides === 'object' ? defaultsDeep(overrides, defaultLocale) : defaultLocale;
  }, [overrides]);
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
