import React, { createContext, FC, useContext } from 'react';
import { ISerializers } from '../serializers';

const ctx = createContext<ISerializers<any, any>>(null!);

export const useSerializers = () => {
  return useContext(ctx);
};

export const SerializersProvider: FC<{value?: ISerializers<any, any>}> = ({value, children}) => {
  return <ctx.Provider value={value || {}}>{children}</ctx.Provider>;
};
