export interface ISimpleObservable<T> {
  get(): T;
  set(value: T): void;
  subscribe(observer: ISimpleObserver<T>): IDisposer;
  destroy(): void;
}

interface IDisposer {
  (): void;
}

interface ISimpleObserver<T> {
  (value: T): void;
}

export const simpleObservable = <T,>(initialValue: T): ISimpleObservable<T> => {
  let _value = initialValue;
  let _observers: null | Set<ISimpleObserver<T>> = new Set();
  const _notify = () => {
    if (_observers !== null) {
      _observers.forEach((observer) => observer(_value));
    }
  };
  return {
    get: () => _value,
    set: (value) => {
      _value = value;
      _notify();
    },
    subscribe: (observer: ISimpleObserver<T>, skipFirst = false) => {
      if (_observers !== null) {
        _observers.add(observer);
        if (!skipFirst) {
          observer(_value);
        }
      }
      return () => {
        if (_observers !== null) {
          _observers.delete(observer);
        }
      };
    },
    destroy: () => {
      _observers = null;
    }
  };
};
