/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, Fragment, useCallback} from 'react';
import {CheckboxField, ICheckboxFieldProps} from './ui/CheckboxField';
import {useLocale} from '../hooks/locale';
import {usePreferences, useSetPreferences} from '../hooks/tableState';
import {Def} from '../utils/types';

export const GroupBy: FC = () => {
  const locale = useLocale();
  const {groupingIsActive: _groupingIsActive, groupingColumnFields} = usePreferences();
  const setPreferences = useSetPreferences();
  const forbidGrouping = groupingColumnFields.length === 0;
  const groupingIsActive = !forbidGrouping && _groupingIsActive;
  const onChange = useCallback<Def<ICheckboxFieldProps['onChange']>>(
    (e) => {
      setPreferences((prev) => ({...prev, groupingIsActive: e.checked}));
    },
    [setPreferences]
  );
  return forbidGrouping ? null : (
    <Fragment>
      <CheckboxField label={locale.groupEvents} checked={groupingIsActive} onChange={onChange} />
    </Fragment>
  );
};
