/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Button} from 'primereact/button';
import {isEqual} from 'lodash-es';
import {cx} from '../utils/cx';
import {useApplyFilter, useCancelFilter, useNextFilter, useResetFilter} from '../hooks/nextFilter';
import {useLocale} from '../hooks/locale';
import {useFilter, useFilterDefaults} from '../hooks/tableState';
import {FC, useCallback} from 'react';

export const FilterActions: FC<JSX.IntrinsicElements['div'] & {field: string}> = ({field, ...rest}) => {
  const locale = useLocale();
  const filter = useFilter();
  const defaults = useFilterDefaults();
  const nextFilter = useNextFilter();
  const apply = useApplyFilter();
  const applyActive = !isEqual(nextFilter[field], filter[field]);
  const onApply = useCallback(() => {
    apply(field);
  }, [apply, field]);
  const cancel = useCancelFilter();
  const cancelActive = applyActive;
  const onCancel = useCallback(() => {
    cancel(field);
  }, [cancel, field]);
  const reset = useResetFilter();
  const resetActive = !isEqual(filter[field], defaults[field]);
  const onReset = useCallback(() => {
    reset(field);
  }, [reset, field]);
  return (
    <div css={mainCss} {...rest}>
      <Button
        label={locale.applyFilters}
        disabled={!applyActive}
        className={applyActive ? undefined : 'p-button-secondary'}
        onClick={onApply}
        data-cy='apply-filters-button'
      />
      <Button
        icon={'mdi mdi-undo-variant'}
        disabled={!cancelActive}
        className={'p-button-outlined p-button-secondary'}
        tooltip={locale.cancelFilters}
        tooltipOptions={{position: 'bottom'}}
        onClick={onCancel}
        data-cy='cancel-active-button'
      />
      <Button
        icon={'mdi mdi-filter-off'}
        disabled={!resetActive}
        className={cx(['p-button-outlined', resetActive ? 'p-button-danger' : 'p-button-secondary'])}
        tooltip={locale.resetFilters}
        tooltipOptions={{position: 'bottom'}}
        onClick={onReset}
        data-cy='filter-off-button'
      />
    </div>
  );
};

// language=SCSS
const mainCss = css`
  & {
    margin-top: calc(20rem / var(--bfs));
    display: flex;
    align-items: center;

    > button {
      margin-right: calc(15rem / var(--bfs));
    }

    > button:last-of-type {
      margin-right: 0;
    }

    > button:first-of-type {
      flex-grow: 1;
    }
  }
`;
