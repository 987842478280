/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useCallback} from 'react';
import {isArray, isNumber, refine} from '../../utils/basicValidators';
import {useCancelFilter, useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {IRangeFilter} from '../../filterTypes';
import {InputText} from 'primereact/inputtext';
import {Slider, SliderProps} from 'primereact/slider';
import {FilterActions} from '../FilterActions';
import {paddingCss, widthCss} from './styles';
import {Def} from '../../utils/types';
import {branch} from '../../utils/branch';

export const RangeFilter: FC<{
  field: string;
  description: IRangeFilter;
}> = ({field, description: {template, toFixed = 0, min, max, step = 1, noneText, anyText}}) => {
  const nextFilter = useNextFilter<string, {[P in string]: IRangeFilter}>();
  const setNextFilter = useSetNextFilter<string, {[P in string]: IRangeFilter}>();
  const cancel = useCancelFilter();
  const value = nextFilter[field];
  const onChange = useCallback<Def<SliderProps['onChange']>>(
    (e) => {
      branch(isRange)(e.value)(([n1, n2]) => {
        let value = [n1, n2].sort();
        if (n1 === min && n2 === max) {
          value = [-Infinity, Infinity];
        } else if (n1 === min && n1 === n2) {
          value = [NaN, NaN];
        }
        setNextFilter((prev) => ({
          ...prev,
          [field]: value
        }));
      }, cancel);
    },
    [field, setNextFilter, min, max, cancel]
  );
  const normalized = normalize(value, min, max);
  const valueAsString = toString(normalized, min, max, noneText, anyText, template, toFixed);
  return (
    <div css={[paddingCss, widthCss]}>
      <div className={'p-fluid p-field p-input-filled'}>
        <InputText readOnly disabled value={valueAsString} />
        {min < max && <Slider range value={normalized} min={min} max={max} step={step} onChange={onChange} />}
      </div>
      <FilterActions field={field} />
    </div>
  );
};

type IRange = [number, number];
const isRange = refine(isArray(isNumber), (v): v is IRange => v.length === 2 && v[0] <= v[1]);

const normalize = (value: unknown, min: number, max: number): IRange => {
  if (isRange(value) && isRange([min, max])) {
    if (isNaN(value[0]) || isNaN(value[1])) {
      return [min, min];
    } else {
      return [Math.max(min, value[0]), Math.min(max, value[1])];
    }
  } else if (isRange([min, max])) {
    return [min, max];
  } else {
    return [-Infinity, Infinity];
  }
};

const toString = (
  [v1, v2]: IRange,
  min: number,
  max: number,
  noneText: string,
  anyText: string,
  template?: string,
  toFixed?: number
): string => {
  if (v1 === min && v2 === min) {
    return noneText;
  } else if (v1 === min && v2 === max) {
    return anyText;
  } else {
    const toString = (v: number) => (toFixed ? v.toFixed(toFixed) : v.toString());
    if (template) {
      return template.replace('{{1}}', toString(v1)) && template.replace('{{2}}', toString(v2));
    } else {
      return `${toString(v1)} - ${toString(v2)}`;
    }
  }
};
