/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, ReactElement, useEffect, useRef, useState} from 'react';
import {ITableProps} from './ITableProps';
import {ISimpleObservable, simpleObservable} from './utils/simpleObservable';
import ResizeObserver from 'resize-observer-polyfill';
import {TableBody} from './components/TableBody';
import {TableStateProvider, usePreferencesDefaults, useSetPreferences, useTableProperties} from './hooks/tableState';
import {NextFilterProvider} from './hooks/nextFilter';
import {LocaleProvider, useLocale} from './hooks/locale';
import {DataProvider} from './hooks/data';
import {IFilterDescription} from './filterTypes';
import {TableHeader} from './components/TableHeader';
import {RoutingProvider, SettingsRoute} from './hooks/tabRouting';
import {SelectedColumns} from './components/SelectedColumns';
import {SerializersProvider} from './hooks/serializers';
import {GroupingColumns} from './components/GroupingColumns';
import {useObservable} from './hooks/useObservable';
import {Button} from 'primereact/button';
import {$smButton} from './components/ui/smButtonCss';
import { ApiRepositoryProvider } from './hooks/useApiRepository';

export function Table<T extends {}, K extends string, FK extends K, FD extends IFilterDescription<FK>>(
  props: ITableProps<T, K, FK, FD>
): ReactElement {
  const outerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [widthObs] = useState(() => simpleObservable(0));
  const [heightObs] = useState(() => simpleObservable(0));
  useEffect(() => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    const header = inner?.querySelector('header');
    if (outer && inner && header) {
      const scrollable = () => inner.querySelector('.p-datatable-scrollable-body');
      let outerHeight = 0;
      let innerHeight = 0;
      let headerHeight = 0;
      let width = 0;
      let current = 0;
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.target === outer) {
            outerHeight = entry.contentRect.height;
          } else if (entry.target === inner) {
            innerHeight = entry.contentRect.height;
            width = entry.contentRect.width;
          } else if (entry.target === header) {
            headerHeight = entry.contentRect.height;
          }
        });
        widthObs.set(width);
        heightObs.set(outerHeight - headerHeight);
        const el = scrollable();
        if (el) {
          current = el.clientHeight + outerHeight - innerHeight;
          (el as HTMLElement).style.height = Math.floor(current) + 'px';
        }
      });
      observer.observe(header);
      observer.observe(outer);
      observer.observe(inner);
      return () => {
        observer.unobserve(outer);
        observer.unobserve(inner);
        observer.disconnect();
      };
    }
    return undefined;
  }, [widthObs, heightObs]);
  const {
    title,
    tabs,
    additionalControls,
    onRowClick,
    onRowDoubleClick,
    selectObs,
    numberOfPinnedColumns,
    columnExpectedWidth,
    serializers,
    locale,
    loadingObs,
    showPageReport,
    lib
  } = props;
  return (
    <div ref={outerRef} css={containerCss}>
      <div ref={innerRef}>
        <RoutingProvider>
          <LocaleProvider overrides={locale}>
            <ApiRepositoryProvider lib={lib}>
              <SerializersProvider value={serializers}>
                <TableStateProvider {...props}>
                  <DataProvider>
                    <NextFilterProvider>
                      <header>
                        <TableHeader
                          loadingObs={loadingObs}
                          title={title}
                          tabs={tabs}
                          additionalControls={additionalControls}
                        />
                      </header>
                      <main>
                        <SettingsRoute
                          left={
                            <TableBody
                              widthObs={widthObs}
                              onRowClick={onRowClick}
                              onRowDoubleClick={onRowDoubleClick}
                              selectObs={selectObs}
                              numberOfPinnedColumns={numberOfPinnedColumns}
                              columnExpectedWidth={columnExpectedWidth}
                              allowExport={!!serializers}
                              showPageReport={showPageReport}
                            />
                          }
                          right={<Settings heightObs={heightObs} />}
                        />
                      </main>
                    </NextFilterProvider>
                  </DataProvider>
                </TableStateProvider>
              </SerializersProvider>
            </ApiRepositoryProvider>
          </LocaleProvider>
        </RoutingProvider>
      </div>
    </div>
  );
}

// language=SCSS
const containerCss = css`
  & {
    padding: calc(100rem / var(--bfs)) calc(30rem / var(--bfs)) calc(30rem / var(--bfs));
    height: 100vh;
  }
  & > div {
    position: relative;
  }
`;

const Settings: FC<{heightObs: ISimpleObservable<number>}> = ({heightObs}) => {
  const locale = useLocale();
  const height = useObservable(heightObs);
  const {isGroupingOptional = true} = useTableProperties();
  const preferencesDefaults = usePreferencesDefaults();
  const setPreferences = useSetPreferences();
  return (
    <div css={$setting} style={{height}}>
      <section data-cy='columns-analytic-section'>
        <h3>
          <span data-cy='columns-title'>{locale.settingsLocale.columnsTitle}</span>
          <Button
            className={'p-button-secondary p-button-outlined p-button-sm'}
            css={$smButton}
            icon={'mdi mdi-restore'}
            tooltip={locale.reset}
            onClick={() => {
              setPreferences((prev) => {
                return {
                  ...prev,
                  selectedColumnFields: preferencesDefaults.selectedColumnFields
                };
              });
            }}
            data-cy='restore-button'
          />
        </h3>
        <SelectedColumns />
      </section>
      {isGroupingOptional && preferencesDefaults.groupingColumnFields.length > 0 && (
        <section data-cy='grouping-analytic-section'>
          <h3>
            <span data-cy='grouping-title' >{locale.settingsLocale.groupingTitle}</span>
            <Button
              className={'p-button-secondary p-button-outlined p-button-sm'}
              css={$smButton}
              icon={'mdi mdi-restore'}
              tooltip={locale.reset}
              onClick={() => {
                setPreferences((prev) => {
                  return {
                    ...prev,
                    groupingColumnFields: preferencesDefaults.groupingColumnFields
                  };
                });
              }}
              data-cy='restore-button'
            />
          </h3>
          <GroupingColumns />
        </section>
      )}
    </div>
  );
};

const $setting = css`
  overflow-x: hidden;
  overflow-y: auto;
  mask: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 1) var(--spacer),
    rgba(0, 0, 0, 1) calc(100% - var(--spacer)),
    transparent 100%
  );

  position: relative;
  display: flex;
  flex-direction: column;

  > section {
    padding-top: calc(30rem / var(--bfs));
    padding-bottom: calc(15rem / var(--bfs));

    > h3 {
      margin: 0 0 calc(30rem / var(--bfs)) 0;
      padding: 0;
      font-weight: 500;
      font-size: var(--font-size-h3);
      height: 1em;
      display: flex;
      align-items: center;

      > button {
        margin-left: var(--spacer-sm);
      }
    }
  }
`;
